import axios from 'axios'

export const createSubscriber = (email) => {
  const url = 'https://remotejobsclub.com/.netlify/functions/add'

  return axios({
    url: url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify({
      email_address: email,
    })
  }).catch(error => {
    return { data: error.response.data }
  }).then(response => response.data)
}
