import React from 'react'
import { Link } from 'gatsby'
import styles from './Header.module.css'
import logo from '../images/logo.png'

const Header = ({ siteTitle }) => (
  <div className={styles.root}>
    <div className={styles.logo}>
      <Link to={'/'}><img alt="Logo" src={logo} /></Link>
    </div>
    <div className={styles.nav}>
      <Link to={'/about'}>About</Link> / 
      <Link className={styles.post} to={'/blog'}>Blog</Link> /
      <Link className={styles.post} to={'/post-a-job'}>Post a Job</Link> 
    </div>
  </div>
)

export default Header
