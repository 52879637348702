import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import ExitPopup from './ExitPopup'
import Header from './Header'
import Footer from './Footer'
import './normalizer.css'
import styles from './layout.module.css'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={data => (
      <div className={styles.root}>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { property: 'og:locale',      content: 'en_US' },
            { property: 'og:title',       content: data.site.siteMetadata.title },
            { property: 'og:description', content: data.site.siteMetadata.description },
            { name:     'description',    content: data.site.siteMetadata.description },
            { property: 'og:type',        content: 'website' },
            { property: 'og:url',         content: 'https://remotejobsclub.com' },
            { property: 'og:site_name',   content: data.site.siteMetadata.title },
            { name:     'twitter:title',        content: data.site.siteMetadata.title },
            { name:     'twitter:description',  content: data.site.siteMetadata.description },
            { name:     'twitter:creator',      content: '@sanjeevan84' },
            { name:     'twitter:site',         content: '@theremotejobs' },
          ]}
          link={[
            { rel: 'stylesheet',
              href: "https://use.fontawesome.com/releases/v5.3.1/css/all.css",
              crossorigin: "anonymous",
              integrity: "sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU"
            },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <Header siteTitle={data.site.siteMetadata.title} />
        <div className={styles.page}>
          {children}
        </div>
        <ExitPopup />
        <Footer />
      </div>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
