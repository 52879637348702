import React from 'react'
import { navigate } from 'gatsby'
import styles from './SignupWithEmail.module.css'
import className from 'classnames'
import { createSubscriber } from '../services'

export default class SignupWithEmail extends React.Component {

  state = {
    email: '',
    error: null,
    loading: false
  }

  validateEmail = email => {
      // eslint-disable-next-line
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
  }

  handleEmailChange = evt => {
    this.setState({ email: evt.target.value, error: null })
  }

  handleSignup = evt => {
    evt.preventDefault()

    if (this.state.email === '') {
      this.setState({ error: 'Please enter an email address' })
      return
    }

    if (!this.validateEmail(this.state.email)) {
      this.setState({ error: 'That doesn\'t look like a valid email address' })
      return
    }

    this.setState({ loading: true })

    createSubscriber(this.state.email).then(data => {
      if (data.error) {
        this.setState({ error: data.message, loading: false })
        return
      } else {
        navigate('/almost')
      }
    })
  }

  render() {
    let btnClass = className(styles.btn, {
      [styles.loading]: this.state.loading
    })
    let btnText = this.state.loading ? <span><i className="fa fa-spinner fa-spin"></i> Submitting...</span> : 'Send me remote jobs!'
    return (
      <div className={styles.root}>
        <form onSubmit={this.handleSignup}>
          <div className={styles.inline}>
            <input onChange={this.handleEmailChange} value={this.state.email} className={styles.input} type="text" placeholder="Your email address" />
            <button onClick={this.handleSignup} className={btnClass} type="button">{btnText}</button>
          </div>
          { this.state.error ? <p className={styles.error}>{this.state.error}</p> : null }
        </form>
      </div>
    )
  }
}
