import React from 'react'
import ExitIntent from '../exit-intent'
import styles from './ExitPopup.module.css'

import SignupWithEmail from './SignupWithEmail'

export default class ExitPopup extends React.Component {

  state = {
    showModal: false,
  }

  componentDidMount() {
    this.removeEvents = ExitIntent({
      onExitIntent: () => {
        this.setState({ showModal: true })
      }
    })
  }

  componentWillUnmount() {
    this.removeEvents()
  }

  handleClose = evt => {
    evt.preventDefault()
    this.setState({ showModal: false })
  }

  render() {
    if (!this.state.showModal) {
      return <div>&nbsp;</div>
    }

    return (
      <div className={styles.root}>
        <div className={styles.content}>
          <div className={styles.title}>You're already reading this so you might as well let me finish my point.</div>
          <p>Look, you came to this site because you saw something cool. But here's the deal. This site, also known as The Remote Jobs Club is a weekly email that sends you the best remote jobs for free.</p>
          <p>Give the email a try. You can always unsubscribe.</p>
          <div className={styles.emailContainer}>
            <SignupWithEmail />
          </div>
          <a className={styles.exit} href="#" onClick={this.handleClose}>(Close)</a>
        </div>
      </div>
    )
  }
}
