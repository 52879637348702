import React from 'react'
import styles from './Footer.module.css'

const Footer = () => {
  const year = new Date().getFullYear()
  return (
    <div className={styles.root}>
        &copy; {year} RemoteJobsClub. Built with <i className="fas fa-fw fa-heart" /> in London, used by people everywhere.
    </div>
  )
}

export default Footer
