
export default function ExitIntent (options = {}) {
  const defaultOptions = {
    threshold: 20,
    maxDisplays: 1,
    eventThrottle: 200,
    onExitIntent: () => {}
  }

  return (function () {
    const config = {...defaultOptions, ...options}
    const eventListeners = new Map()

    const getDisplays = () => {
      let v = localStorage.getItem('displays')
      if (v !== null) {
        return parseInt(v)
      }
      return 0
    }
    const setDisplays = (d) => {
      return localStorage.setItem('displays', d)
    }

    const addEvent = (eventName, callback) => {
      document.addEventListener(eventName, callback, false)
      eventListeners.set(`document:${eventName}`, {eventName, callback})
    }

    const removeEvent = key => {
      const {eventName, callback} = eventListeners.get(key)
      document.removeEventListener(eventName, callback)
      eventListeners.delete(key)
    }

    const shouldDisplay = position => {
      const displays = getDisplays()
      if (position <= config.threshold && displays < config.maxDisplays) {
        setDisplays(displays+1)
        return true
      }
      return false
    }

    const mouseEnter = evt => {
      return
    }

    const mouseLeave = evt => {
      if (evt.clientY > 20) {
        return
      }
      if (shouldDisplay(evt.clientY)) {
        config.onExitIntent()
      }
      if (getDisplays() >= config.maxDisplays) {
        removeEvents()
      }
    }

    const removeEvents = () => {
      eventListeners.forEach((value, key, map) => removeEvent(key))
    }

    addEvent('mouseenter', mouseEnter)
    addEvent('mouseleave', mouseLeave)

    return removeEvents
  })()
}
